var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "promotion-card" }, [
    _c("div", { staticClass: "fw500 flex bet a-center" }, [
      _c("div", {
        style: { "margin-top": "10px", "margin-right": "15px" },
        domProps: {
          textContent: _vm._s(
            _vm.$t(["Deal", "Coupon", "Upsell"][_vm.promotion.type])
          ),
        },
      }),
    ]),
    _c(
      "div",
      {
        staticClass: "promotion-card__main",
        style: { width: "100%", display: "flex", "border-bottom": "none" },
        on: { click: _vm.data.click },
      },
      [
        _c(
          "div",
          { staticClass: "promotion-card__main_main", style: { width: "80%" } },
          [
            _c("div", { staticClass: "promotion-card__main_name" }, [
              _c("div", {
                domProps: {
                  textContent: _vm._s(
                    _vm.he ? _vm.promotion.name_he : _vm.promotion.name_en
                  ),
                },
              }),
            ]),
            _c("div", { staticClass: "promotion-card__main_name" }, [
              _c("div", {
                staticClass: "grey",
                domProps: { textContent: _vm._s(_vm.$t("Branches")) },
              }),
              _c("div", {
                domProps: { textContent: _vm._s(_vm.branchesText) },
              }),
            ]),
            _c("div", { staticClass: "promotion-card__main_name" }, [
              _c("div", {
                staticClass: "grey",
                domProps: { textContent: _vm._s(_vm.$t("Date of creation")) },
              }),
              _c("span", {
                staticClass: "mis4",
                domProps: {
                  textContent: _vm._s(
                    _vm.filters.clockTimeDate(_vm.data.promotion.publish)
                  ),
                },
              }),
            ]),
            _c("div", { staticClass: "promotion-card__main_name" }, [
              _c("div", {
                staticClass: "grey",
                domProps: { textContent: _vm._s(_vm.$t("End date")) },
              }),
              _vm.data.promotion.expiration
                ? _c("span", {
                    staticClass: "mis4",
                    domProps: {
                      textContent: _vm._s(
                        _vm.filters.clockTimeDate(_vm.data.promotion.expiration)
                      ),
                    },
                  })
                : _c("span", {
                    staticClass: "mis4",
                    domProps: { textContent: _vm._s(_vm.$t("Never")) },
                  }),
            ]),
          ]
        ),
        _c("div", { style: { display: "flex", "flex-direction": "column" } }, [
          _c(
            "div",
            { staticClass: "promotion-card__main_name" },
            [
              _vm.data.active
                ? _c("swich-button", {
                    style: { "margin-bottom": "25px" },
                    attrs: { value: _vm.promotion.active },
                    on: { input: _vm.data.active },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("div", { staticClass: "promotion-card__main_name" }, [
            _c("div", {
              staticClass: "grey",
              domProps: { textContent: _vm._s(_vm.$t("Redeems")) },
            }),
            _vm.promotion.used_orders
              ? _c("div", {
                  style: { display: "flex", "justify-content": "center" },
                  domProps: {
                    textContent: _vm._s(
                      _vm.promotion.used_orders.reduce(function (acc, br) {
                        return acc + br.count
                      }, 0)
                    ),
                  },
                })
              : _vm._e(),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }